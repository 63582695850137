<template>
  <ion-page>
    <ion-content>
      <div class="main">
        <div class="topBackground">
          <ion-text class="regular cWhite ion-text-center" >
            <h1 class="ion-no-margin menuTitleFont">Mes Disponibilités</h1>
          </ion-text>
        </div>
        <div class="maxWidth">
          <ion-text color="secondary">
            <h1 class="ion-no-padding ion-margin">Pour kiffer le maximum de missions, indique tes <span style="color: #f55c6c">INDISPONIBILITÉS</span></h1>
          </ion-text>
          <Calendar
              locale='fr'
              class="widthCalendar"
              is-expanded
              trim-weeks
              :attributes="attributes"
              @dayclick="onDayClick"
          />
          <div v-if="isModified">
            <ion-button v-on:click="cancel" color="light">
              Annuler
            </ion-button>
            <ion-button @click="validate">
              Valider la sélection
            </ion-button>

          </div>
          <ion-text color="primary">
            <h3>{{msgError}}</h3>
          </ion-text>
        </div>
        </div>
      <div v-if="!isDesktop" class="footerBackground">
            <img class="ion-margin-bottom logoFooter" src="../../public/assets/icon/alloopharma-logo.png" >
          </div>
          <div v-else class="footerNotMobile">
            <img class="ion-margin-bottom logoFooter" src="../../public/assets/icon/alloopharma-logo.png" >
          </div>

    </ion-content>
  </ion-page>
</template>

<script >
import { IonPage, IonContent, IonText, IonButton } from '@ionic/vue';
import { Calendar } from 'v-calendar';
import moment from 'moment';
import store from '@/store'
import {updateUnavailabilities} from "@/services/api";
//import Availability from '@/components/Availability.vue';
//import PlanningSlot from '@/components/PlanningSlot.vue';


export default  {
  name: 'Availabilities',
  components: { Calendar, IonPage, IonContent, IonText, IonButton },
  data() {
    return {
      isMsgValidation: false,
      msgError: "",
      isModified: false,
      initDays: [],
      days: []
    };
  },
  ionViewDidEnter() {
    this.initDays  = store.getters.unavailabilities
    this.days = JSON.parse(JSON.stringify(this.initDays));
    },
  computed: {
    isDesktop() {
      return store.getters.isDesktop
    },
    attributes() {
      return this.days.map(day => ({
        highlight: {
          color: "red",
          fillMode: "solid"
        },
        dates: day,
      }));
    },
  },
  methods: {
    onDayClick(day) {
      this.msgError = ""
      const now = moment().format('YYYY-MM-DD');
      const isAfter = moment(day.id).isAfter(now);
      const isSame = moment(day.id).isSame(now);
      if(!isAfter && !isSame ){
        this.msgError = "Cette date est passée"
        return
      }
      this.isModified = true
      this.isMsgValidation = false
      this.isMsgError = false
      const dayEqual= (element) => element == day.id;
      const indexDayFinded = this.days.findIndex(dayEqual)
      if (indexDayFinded > -1) {
        this.days.splice(indexDayFinded, 1);

      } else {
        this.days.push(day.id);
      }
    },
    cancel() {
      this.days = this.initDays
      this.isModified = false
    },
    validate() {
      updateUnavailabilities(this.days).then(() => {
        this.isModified = false
      })

    }
  },
}
</script>
<style scoped>
.menuTitleFont {
  padding:50px;
  margin-top: -20px
}
ion-button {
  margin-top: 20px
}
.widthCalendar{
  width: 100%;
}


.red {
  color: pink
}
</style>
